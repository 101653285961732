import { tns } from '../../node_modules/tiny-slider/src/tiny-slider';

let slider = false;

(function () {

  if (!document.body.classList.contains('page')) return this;

  slider = tns({
    autoHeight: 'auto',
    autoplay: false,
    autoplayButtonOutput: false,
    container: '#bg-slider',
    controls: false,
    items: 1,
    loop: true,
    nav: false,
    mode: 'gallery',
    slideBy: 'page',
    animateDelay: 0,
    speed: 0,
  });

})();

export default slider;
