import objectFitImages from 'object-fit-images';
objectFitImages();

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import ScrollOut from "scroll-out";

import './_fonts';
import slider from './_slider';
import './_scroller';
import './_form';


function setScrollOut(){

  ScrollOut({
    targets: ".slider-controls",
    onShown: function(element, ctx, scrollingElement) {
      document.body.classList.add('slider-shown');
    },
    onHidden: function(element, ctx, scrollingElement) {
      document.body.classList.remove('slider-shown');
    }
  });

}
setScrollOut();

const slider_next = document.querySelector('.slider-controls__next');
const slider_prev = document.querySelector('.slider-controls__prev');
slider_next.addEventListener('click', function(e) {
  slider.goTo('next');
});
slider_prev.addEventListener('click', function(e) {
  slider.goTo('prev');
});

const checkScrollSpeed = (function(settings){
  settings = settings || {};

  let lastPos, newPos, timer, delta,
  delay = settings.delay || 50; // in "ms" (higher means lower fidelity )

  function clear() {
    lastPos = null;
    delta = 0;
  }

  clear();

  return function(){
    newPos = window.scrollY;
    if ( lastPos != null ){ // && newPos < maxScroll
      delta = newPos - lastPos;
    }
    lastPos = newPos;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
})();

let scroll_timer;
const container = document.querySelector('.site-container');
window.addEventListener('scroll', function(e) {
  const delta = Math.abs(checkScrollSpeed());
  const opacity = 1-(Math.abs(delta)/100)

  container.style.opacity = Math.pow(opacity, 8);

  if (!document.body.classList.contains('scrolling')) {
    document.body.classList.add('scrolling');
  }
  if (delta == 1) {
    container.style.opacity = 1;
  }
  if (scroll_timer) {
    clearTimeout(scroll_timer);
  }
  // n secスクロールしない場合はscrollFinishイベントを呼び出し
  scroll_timer = setTimeout(function () {
    scroll_timer = null;
    document.body.classList.remove('scrolling');
    slider.goTo('next');
  }, 200);
});
