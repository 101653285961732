const FontFaceObserver = require('fontfaceobserver');

// const ssGlyphish = document.createElement('link');
// ssGlyphish.rel = 'stylesheet';
// ssGlyphish.href = '/fonts/ss-glyphish-outlined.css';
// ssGlyphish.onload = function () {
//   document.body.classList.add('loaded-glyphish-icons');
// };
// document.body.appendChild(ssGlyphish);

//<script src="//fast.fonts.net/jsapi/2dcd91ed-5ffa-4ef8-9bca-1f30b53331d1.js"></script>
//<script src="//fast.fonts.net/jsapi/38837584-8685-4146-91ee-d06660387260.js"></script>
const fontscom = [];
fontscom[0] = document.createElement('script');
fontscom[1] = document.createElement('script');
fontscom[0].src = '//fast.fonts.net/jsapi/2dcd91ed-5ffa-4ef8-9bca-1f30b53331d1.js';
fontscom[1].src = '//fast.fonts.net/jsapi/38837584-8685-4146-91ee-d06660387260.js';
fontscom[0].async = true;
fontscom[1].async = true;
fontscom[0].onload = function () {
  const Axis = new FontFaceObserver('AxisStd-Regular');
  const Futsura = new FontFaceObserver('Futura ND W01 Bold');

  Promise.all([
    Axis.load(),
    Futsura.load(),
  ]).then(function (value) {
    // console.log('sucess',value);
    document.body.classList.add('loaded-fontscom');
  },function(reason){
    // console.log('error',reason);
    document.body.classList.add('loaded-fontscom');
  });
};
document.body.appendChild(fontscom[0]);
document.body.appendChild(fontscom[1]);
