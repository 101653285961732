(function () {

  if (!document.body.classList.contains('page')) return this;

  // const sample = document.querySelector('.sample');
  const radio = document.querySelectorAll('input[type="radio"]');
  const checkboxes = document.querySelectorAll('.checkbox-sub');
  const uncheckboxes = document.querySelectorAll(`input[type="checkbox"]`);

  radio.forEach((item, i) => {
    item.addEventListener('change',(e) => {
      e.preventDefault();
      const show_checkbox = document.querySelector(`.set-${e.target.value}`);
      checkboxes.forEach((item, i) => {
        item.classList.remove('show');
      });
      uncheckboxes.forEach((item, i) => {
        item.checked = false;
      });
      show_checkbox.classList.add('show');
      item.checked = true;
    });
  });


})();
