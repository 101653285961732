(function () {

  if (!document.body.classList.contains('home')) return this;

  // const sample = document.querySelector('');
  // const sample = document.querySelectorAll('');
  const scroller = document.getElementById('scroller');
  const html = document.documentElement;

  function update(){
    //表示範囲を割合で計算
    const scroller_height = window.innerHeight / html.scrollHeight * 100
    //スクロール位置を割合で計算
    const scroller_position = html.scrollTop / html.scrollHeight * 100

    scroller.style.height = `${scroller_height}%`;
    scroller.style.top = `${scroller_position}%`;
    // console.log(
    //   "scroller_height:", scroller.style.height,
    //   "scroller_position:", scroller.style.top
    // );
  }

  window.addEventListener('load', update);
  window.addEventListener('resize', update);
  window.addEventListener('scroll', update);

})();
